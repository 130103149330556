import React, { useCallback } from 'react';
import { Item, Spacer, View } from './styles';
import { CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
function PaymentInfoComponent({ contract }) {
  const { t } = useTranslation();
  const paymentDetails = {
    paymentMethod:
      contract?.contractPaymentDetails?.paymentMethod &&
      contract?.contractPaymentDetails?.paymentMethod !== null
        ? contract?.contractPaymentDetails?.paymentMethod === 'BANK_TRANSFER'
          ? t('rechnung')
          : contract?.contractPaymentDetails?.paymentMethod === 'STRIPE_SEPA'
            ? t('sepaStripemode')
            : t('LBLsepamode')
        : null,
    contractMandateReference:
      contract?.contractPaymentDetails &&
      contract?.contractPaymentDetails?.contractMandateReference !== null
        ? contract?.contractPaymentDetails?.contractMandateReference
        : null,
    contractIban:
      contract?.contractPaymentDetails?.contractIban &&
      contract?.contractPaymentDetails?.contractIban !== null
        ? contract?.contractPaymentDetails?.contractIban
        : null,
    debitAccountNr: contract?.erpSystemParameters?.debitAccountNr,
    costCenterNumber: contract?.erpSystemParameters?.costCenterNumber,
    //vatId: contract?.erpSystemParameters?.vatId,
    vatId:
      contract?.erpSystemParameters?.vatId !== undefined &&
      contract?.erpSystemParameters?.vatId !== null
        ? `${contract?.erpSystemParameters?.vatId}%`
        : null,

  };

  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'paymentMethod':
          return contract?.contractPaymentDetails === null ||
            contract?.contractPaymentDetails?.paymentMethod === null
            ? null
            : t('paymethod');
        case 'contractMandateReference':
          return contract?.contractPaymentDetails === null ||
            contract?.contractPaymentDetails?.contractMandateReference === null
            ? null
            : t('mandateref');
        case 'contractIban':
          return contract?.contractPaymentDetails === null ||
            contract?.contractPaymentDetails?.contractIban === null
            ? null
            : 'IBAN';
        case 'debitAccountNr':
          return contract?.erpSystemParameters === null ||
            contract?.erpSystemParameters?.debitAccountNr === null
            ? null
            : t('custacnum');
        case 'costCenterNumber':
          return contract?.erpSystemParameters === null ||
            contract?.erpSystemParameters?.costCenterNumber === null
            ? null
            : t('costcnum');
        case 'vatId':
          return contract?.erpSystemParameters === null ||
            contract?.erpSystemParameters?.vatId === null
            ? null
            : t('vatId');
        default:
          return key;
      }
    },
    [contract]
  );
  return (
    <>
      <View>
        <CardContent
          style={{
            display:
              (contract?.contractPaymentDetails?.paymentMethod === null &&
                contract?.contractPaymentDetails?.contractMandateReference ===
                  null &&
                contract?.contractPaymentDetails?.contractIban === null &&
                contract?.erpSystemParameters?.debitAccountNr === null &&
                contract?.erpSystemParameters?.costCenterNumber === null &&
                contract?.erpSystemParameters?.vatId === null) ||
              (contract?.contractPaymentDetails === null &&
                contract?.erpSystemParameters === null)
                ? 'none'
                : 'true',
          }}
        >
          <Typography fontWeight="bold" variant="h4" gutterBottom>
            {t('lblMiscInfo')}
          </Typography>
          <Spacer mb={4} />
          <Typography variant="body2" component="div" gutterBottom>
            {paymentDetails && (
              <>
                {Object.entries(paymentDetails).map(([key, value], index) => {
                  return (
                    <>
                      {renderKey(key) && (
                        <>
                          <Item key={index}>
                            <Typography fontWeight="fontWeightMedium">
                              {renderKey(key)}
                            </Typography>
                            <Typography
                              sx={{
                                maxWidth: 200,
                                whiteSpace: 'pre-wrap',
                                lineBreak: 'normal',
                              }}
                              align="right"
                              fontWeight="fontWeightRegular"
                            >
                              {value}
                            </Typography>
                          </Item>
                        </>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Typography>
        </CardContent>
      </View>
    </>
  );
}

export default PaymentInfoComponent;