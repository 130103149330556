import React, {
  useState,
  lazy,
  Suspense,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import Checkbox from '@mui/material/Checkbox';
import jwtDecode from 'jwt-decode';
import {
  Chip as MuiChip,
  Typography,
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  ListItemText,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import shadows from '@mui/material/styles/shadows';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box } from '@mui/system';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { useAuth } from '../../services/contexts/AuthContext';
import api from '../../Middleware/api/api';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadBillsCSV from './DownloadBillsCSV';
import { useTranslation } from 'react-i18next';
import LogoLoader from '../../components/LogoLoader';
import { useTokenUtils } from '../../services/utils/tokenUtils';
const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  margin-left: 7px;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary'].light};
  color: white !important;  
`;

const FailFeedback = lazy(() => import('../../components/DataTable/Feedback'));
function calculatedates(days) {
  let date = new Date();
  let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  return (
    last.getFullYear() +
    '-' +
    last.toLocaleString('en-US', { month: '2-digit' }) +
    '-' +
    last.toLocaleString('en-US', { day: '2-digit' })
  );
}

function DownloadCenter() {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(calculatedates(7));
  const [endDate, setEndDate] = useState(calculatedates(0));
  const historyFormat = 'CSV';
  const paymentMethodsTypes = [
    { value: 'SEPA', translationKey: 'LBLsepamode' },
    { value: 'BANK_TRANSFER', translationKey: 'rechnung' },
  ];
  const [paymentMethodSelected, setPaymentMethodSelected] = useState('SEPA');

  const initialValues = {
    startDate: startDate,
    endDate: endDate,
  };

  const validationSchema = useMemo(
    () =>
      Yup.object({
        startDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .required(t('LBLReqCockpit')),
        endDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .min(Yup.ref('startDate'), t('LBLENdgStart'))
          .required(t('LBLEndDateReq')),
      }),
    []
  );

  const [ButtonDownloadErpIsSubmitting, setButtonDownloadErpIsSubmitting] =
    useState(false);
  const [
    ButtonDownloadHistoryIsSubmitting,
    setButtonDownloadHistoryIsSubmitting,
  ] = useState(false);

  const { token } = useAuth();
  const [anchorLocation, setAnchorLocation] = useState(false);
  const [anchorCpo, setAnchorCpo] = useState(false);
  const locationName = t('MenuLocation');
  const [cpoId, setCpoId] = useState(t(''));
  const [selectedCpo, setSelectedCpo] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectedLocationWithCpo, setSelectedLocationWithCpo] = useState([]);
  const [stations, setStations] = useState([]);
  const [stationsWithCpo, setStationsWithCpo] = useState([]);
  const [error, setError] = useState(false);
  const [error204, setError204] = useState(false);
  const [error404, setError404] = useState(false);
  const [anchorLocationWithCpo, setAnchorLocationWithCpo] = useState(false);
  const [xmlErrorMessage, setXmlErrorMessage] = useState('');
  const [xmlError, setXmlError] = useState(false);
  const isAllSelected =
    stations.length > 0 && selected.length === stations.length;
  const isAllSelectedStations =
    stationsWithCpo.length > 0 &&
    selectedLocationWithCpo.length === stationsWithCpo.length;

  const decodedToken = token ? jwtDecode(token) : null;
  const { isAdminRole } = useTokenUtils();
  const [displayPage, setDisplayPage] = useState('CSV');
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState([]);

  const handleClick = (component) => {
    setDisplayPage(component);
  };

  //this is used to changed the label of button using useState
  useEffect(() => {
    if (selectedCpo === '') {
      setCpoId(t('LBLOpId'));
    }
  }, [t, i18n.language]);

  const locationButtonComponent = useRef(null);
  const [leftPosition, setLeftPosition] = useState('0');
  useEffect(() => {
    setLeftPosition(
      locationButtonComponent.current.getBoundingClientRect()?.left - 2
    );
  }, [locationButtonComponent]);

  const [locationErpLeftPosition, setLocationErpLeftPosition] = useState('0');
  const locationButtonErp = useCallback(
    (node) => {
      if (node !== null) {
        setLocationErpLeftPosition(node.getBoundingClientRect()?.left);
      }
    },
    [cpoId]
  );

  const [cpoLeftPosition, setCpoLeftPosition] = useState('0');
  const [cpoDropBoxWidth, setCpoDropBoxWidth] = useState('0');
  const cpoButtonComponent = useCallback(
    (node) => {
      if (node !== null) {
        setCpoLeftPosition(node.getBoundingClientRect()?.left);
        setCpoDropBoxWidth(node.getBoundingClientRect()?.width);
      }
    },
    [cpoId]
  );

  const listofstations = (cpoId) => {
    return stations.filter((element) => element.cpoId === cpoId);
  };

  const handlePaymentMethodChange = (event) => {
    if (event.target.value !== undefined) {
      setPaymentMethodSelected(event.target.value);
    }
  };
  const handleCpoIdClick = (event) => {
    setAnchorCpo(true);
  };

  const handleTestCpoIdChange = (event) => {
    const newValue = event.target.value;
    if (event.target.value !== undefined && event.target.value !== 0) {
      setSelectedCpo(newValue);
      setCpoId(newValue);
      setStationsWithCpo([]);
      setSelectedLocationWithCpo([]);
      setStationsWithCpo(listofstations(newValue));
    } else {
      setAnchorCpo(false);
    }
  };

  const handleLocationClick = (event) => {
    setAnchorLocation(true);
  };

  const handleTestLocationChange = (event) => {
    if (event.target.value !== undefined) {
      if (
        event.target.value.includes('all') &&
        selected.length !== stations.length
      ) {
        setSelected(stations?.map((station) => station.locationId));
      } else if (event.target.value.includes('all')) {
        setSelected([]);
      } else {
        setSelected(event.target.value);
      }
    } else {
      setAnchorLocation(false);
    }
  };
  //for selection of location with cpo
  const handleLocationWithCpoClick = (event) => {
    setAnchorLocationWithCpo(true);
  };
  const handleTestLocationWithCpoChange = (event) => {
    if (event.target.value !== undefined) {
      if (
        event.target.value.includes('all') &&
        selectedLocationWithCpo.length !== stationsWithCpo.length
      ) {
        setSelectedLocationWithCpo(
          stationsWithCpo?.map((station) => station.locationId)
        );
      } else if (event.target.value.includes('all')) {
        setSelectedLocationWithCpo([]);
      } else {
        setSelectedLocationWithCpo(event.target.value);
      }
    } else {
      setAnchorLocationWithCpo(false);
    }
  };

  const getDistinctCPOsAndCpoName = (response) => {
    const distinctCPOs = response.reduce((acc, current) => {
      const x = acc.find((item) => item.cpoId === current.cpoId);
      if (!x) {
        acc.push({ cpoId: current.cpoId, cpoName: current.cpoName });
      }
      return acc;
    }, []);

    return distinctCPOs;
  };

  const getStations = async () => {
    setLoading(true);
    api.fetchLocationsForDownloadCenter(token).then((response) => {
      if (response.data.data == null) {
        setStations([]);
      } else {
        setStations(response.data.data.response);
        setOperators(getDistinctCPOsAndCpoName(response.data.data.response));
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getStations();
    return () => {
      setStations([]);
    };
  }, []);

  const handleSubmit = (values, actions) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setButtonDownloadHistoryIsSubmitting(true);
    setLoading(true);
    fetchCSV(values.startDate, values.endDate);
    actions.setSubmitting(false);
  };

  const fetchCSV = (startDateValue, endDateValue) => {
    api
      .fetchCSV(
        historyFormat,
        startDateValue,
        endDateValue,
        selected.toString(),
        token
      )
      .then((response) => {
        if (response.status == 404) {
          setError404(true);
        } else if (response.status == 200) {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Ladehistorie.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          setError(true);
        }
        setButtonDownloadHistoryIsSubmitting(false);
        setLoading(false);
      })
      .catch((reason) => {
        if (reason.response.status === 404) {
          setError404(true);
        } else {
          setError(true);
        }
        setButtonDownloadHistoryIsSubmitting(false);
        setLoading(false);
      });
  };

  const handleFromDatePostingChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setStartDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setStartDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setStartDate(newValue);
      setFieldValue('startDate', newValue);
      console.error('Invalid FromDatePosting input');
    }
  };

  const handleUntilDatePostingChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setEndDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setEndDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setEndDate(newValue);
      setFieldValue('endDate', newValue);
      console.error('Invalid UntilDatePosting input');
    }
  };

  //fetch for ERP CSV file
  const handleSubmitWithCpoIds = (values, actions) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setButtonDownloadErpIsSubmitting(true);
    setLoading(true);
    fetchErpXml(values.startDate, values.endDate);

    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);
    actions.setSubmitting(false);
  };

  //downloading XML file
  const fetchErpXml = (startDateValue, endDateValue) => {
    api
      .fetchErpXml(
        startDateValue,
        endDateValue,
        selectedCpo.toString(),
        selectedLocationWithCpo.toString(),
        paymentMethodSelected,
        token
      )
      .then((response) => {
        if (response.status == 204) {
          setError204(true);
        } else if (response.status == 404) {
          setError404(true);
        } else if (response.status == 200) {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              'ERPDaten_' + format(new Date(endDateValue), 'yyyyMMdd') + '.xml'
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          setError(true);
        }
        setButtonDownloadErpIsSubmitting(false);
        setLoading(false);
      })
      .catch((error) => {
        const errorCodeResponse =
          error?.response?.data?.error?.errorCodeResponse;
        setButtonDownloadErpIsSubmitting(false);
        setLoading(false);
        if (errorCodeResponse === 'BILLING_NOT_FOUND') {
          setXmlError(true);
          setXmlErrorMessage(t('LBLErpXmlDownloadNoBillsFound'));
        } else if (errorCodeResponse === 'CONTRACT_NOT_FOUND') {
          setXmlError(true);
          setXmlErrorMessage(t('LBLErpXmlDownloadNoContractsFound'));
        } else if (errorCodeResponse === 'OPERATOR_NOT_FOUND') {
          setXmlError(true);
          setXmlErrorMessage(t('LBLErpXmlDownloadNoOperatorFound'));
        } else if (errorCodeResponse === 'ERP_VALUE_NOT_FOUND') {
          let errorObj = error?.response?.data?.error?.errorDetail;
          setXmlError(true);
          setXmlErrorMessage(
            t('LBLErpXmlDownloadNoERPValuefound') + ' ' + errorObj
          );
        } else if (errorCodeResponse === 'ERP_SYSTEM_PARAMETERS_NOT_FOUND') {
          let errorObj = error?.response?.data?.error?.errorDetail;
          setXmlError(true);
          setXmlErrorMessage(
            t('LBLErpXmlDownloadNoErpSystemParametersForContract') +
              ' ' +
              errorObj
          );
        } else if (errorCodeResponse === 'DEBIT_ACCOUNT_NR_MISSING') {
          let errorObj = error?.response?.data?.error?.errorDetail;
          setXmlError(true);
          setXmlErrorMessage(
            t('LBLErpXmlDownloadNoDebitAccountNrForContract', { endCustomerId: errorObj})
          );
        } else if (errorCodeResponse === 'COST_CENTER_NR_MISSING') {
          let errorObj = error?.response?.data?.error?.errorDetail;
          setXmlError(true);
          setXmlErrorMessage(
            t('LBLErpXmlDownloadNoCostCenterNumberForContract', { endCustomerId: errorObj})
          );
        } else if (errorCodeResponse === 'VAT_ID_MISSING') {
          let errorObj = error?.response?.data?.error?.errorDetail;
          setXmlError(true);
          setXmlErrorMessage(
            t('LBLErpXmlNoVatId', { endCustomerId: errorObj})
          );
        } else if (errorCodeResponse === 'CLIENT_NUMBER_MISSING') {
          let errorObj = error?.response?.data?.error?.errorDetail;
          setXmlError(true);
          setXmlErrorMessage(
            t('LBLErpXmlDownloadNoClientNumberForErpForOperator') +
              ' ' +
              errorObj
          );
        } else if (errorCodeResponse === 'NET_LEDGER_ACCOUNT_NUMBER_MISSING') {
          let errorObj = error?.response?.data?.error?.errorDetail;
          setXmlError(true);
          setXmlErrorMessage(
            t('LBLErpXmlDownloadNoNetLedgerAccountNrForErpForOperator') +
              ' ' +
              errorObj
          );
        } else if (errorCodeResponse === 'XML_GENERATION_ERROR') {
          setXmlError(true);
          setXmlErrorMessage(t('LBLErpXmlDownloadUnkNownError1'));
        } else if (errorCodeResponse === 'UNKNOWN_ERROR') {
          setXmlError(true);
          setXmlErrorMessage(t('LBLErpXmlDownloadUnkNownError1'));
        } else {
          setXmlError(true);
          setXmlErrorMessage(t('LBLErpXmlDownloadUnkNownError2'));
        }
      });
  };

  const closeErrorFeedback = () => setXmlError(false);

  return (
    <React.Fragment>
      <LogoLoader loading={loading} />
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Download Center
      </Typography>
      <div style={{ margin: '10px' }}>
        <Button
          style={{ width: '140px', height: '35px', margin: '2px' }}
          variant="contained"
          color={displayPage === 'CSV' ? 'primary' : 'inherit'}
          onClick={() => handleClick('CSV')}
        >
          {t('LBLLoadingHostory')}
        </Button>
        <Button
          style={{
            width: '150px',
            height: '35px',
            margin: '2px',
            display:
              decodedToken?.customer_type === 'Partner' ||
              decodedToken?.customer_type === 'Operator' ||
              isAdminRole
                ? true
                : 'none',
          }}
          variant="contained"
          color={displayPage === 'BillingCSV' ? 'primary' : 'inherit'}
          onClick={() => handleClick('BillingCSV')}
        >
          {t('LBLRechnungen')}
        </Button>

        <Button
          style={{
            width: '150px',
            height: '35px',
            margin: '2px',
            display:
              decodedToken?.customer_type === 'Partner' || isAdminRole
                ? true
                : 'none',
          }}
          variant="contained"
          color={displayPage === 'XML' ? 'primary' : 'inherit'}
          onClick={() => handleClick('XML')}
        >
          {t('LBLERPImport')}
        </Button>
      </div>
      <Box
        width="100%"
        sx={{
          position: 'absolute',
          left: 0,
          bottom: '5%',

          '@media (orientation: landscape)': {
            position: 'inherit',
            left: 'unset',
            bottom: 'unset',
          },
        }}
      >
        {displayPage === 'CSV' && (
          <div>
            <div>
              <br />
              <Typography variant="h6" gutterBottom display="inline">
                {t('LBLDownloadHistoryP')}
              </Typography>
              <br />
              <br />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form noValidate>
                    <Stack
                      direction="row"
                      spacing={12}
                      alignItems="center"
                      border={'none'}
                      width="100%"
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '70px',
                          textAlign: 'center',
                          marginTop: '30px',
                          margin: 'auto',
                        }}
                      >
                        <div
                          style={{
                            width: '24%',
                            height: '50px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <div style={{ backgroundColor: 'transparent' }}>
                            <div>
                              <Button
                                endIcon={<ArrowDropDownIcon fontSize="small" />}
                                variant="contained"
                                color="primary"
                                aria-haspopup="true"
                                onClick={handleLocationClick}
                                id="mutiple-select-label"
                                ml={8}
                                ref={locationButtonComponent}
                              >
                                {locationName} 
                                <Chip label={selected.length} size="small" />
                              </Button>
                              <Select
                                id="chart-location"
                                variant="filled"
                                size="small"
                                open={anchorLocation}
                                labelId="mutiple-select-label"
                                input={
                                  <OutlinedInput
                                    sx={{ fontSize: '2rem' }}
                                    label="Tag"
                                  />
                                }
                                multiple
                                value={selected}
                                onChange={handleTestLocationChange}
                                renderValue={(selected) => selected.length}
                                IconComponent={() => null}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      left: `${leftPosition}px !important`,
                                      top: '300px !important',
                                      maxHeight: '200px !important',
                                      fontSize: '2 !important',
                                    },
                                  },
                                  // getcontentanchorel: null,
                                }}
                                onClose={handleTestLocationChange}
                                style={{ display: 'none' }}
                              >
                                <MenuItem value="all" divider>
                                  <Checkbox
                                    size="small"
                                    checked={isAllSelected}
                                    indeterminate={
                                      selected.length > 0 &&
                                      selected.length < stations.length
                                    }
                                  />
                                  <ListItemText
                                    primary={t('LBLALLLocations')}
                                  />
                                </MenuItem>
                                {stations?.map((station) => (
                                  <MenuItem
                                    size="small"
                                    key={station.locationId}
                                    value={station.locationId}
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={
                                        selected.indexOf(station.locationId) >
                                        -1
                                      }
                                    />
                                    <ListItemText
                                      primaryTypographyProps={{
                                        fontSize: '10px',
                                      }}
                                    >
                                      {station.locationId +
                                        ': ' +
                                        station.locationName}
                                    </ListItemText>
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: '24%',
                            height: '50px',
                            textAlign: 'center',
                            float: 'left',
                          }}
                        >
                          <div style={{ backgroundColor: 'transparent' }}>
                            <div>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="de"
                              >
                                <DatePicker
                                  label={t('LBLStartDate')}
                                  PaperProps={{ sx: { boxShadow: shadows[3] } }}
                                  value={values.startDate}
                                  onChange={(newValue) => {
                                    handleFromDatePostingChange(
                                      newValue,
                                      setFieldValue
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextField}
                                      {...params}
                                      required
                                      name="startDate"
                                      margin="none"
                                      autoComplete="off"
                                      helperText={errors.startDate}
                                      error={errors.startDate}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'TT.MM.JJJJ',
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: '24%',
                            height: '50px',
                            textAlign: 'right',
                            float: 'left',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: 'transparent',
                              marginLeft: '50px',
                            }}
                          >
                            <div>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="de"
                              >
                                <DatePicker
                                  PaperProps={{ sx: { boxShadow: shadows[3] } }}
                                  label={t('LBLEndDate')}
                                  value={values.endDate}
                                  onChange={(newValue) => {
                                    handleUntilDatePostingChange(
                                      newValue,
                                      setFieldValue
                                    );
                                  }}
                                  maxDate={dayjs()}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextField}
                                      {...params}
                                      name="endDate"
                                      margin="none"
                                      autoComplete="off"
                                      helperText={errors.endDate}
                                      error={errors.endDate}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'TT.MM.JJJJ',
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: '24%',
                            height: '50px',
                            textAlign: 'right',
                            float: 'left',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: 'transparent',
                              marginLeft: '50px',
                              marginTop: '-15px',
                            }}
                          >
                            <div>
                              <TextField
                                select
                                fullWidth
                                id="historyformat"
                                label="Format"
                                value={historyFormat}
                                name="HistoryFormat"
                              >
                                <MenuItem value={'CSV'}>CSV</MenuItem>
                              </TextField>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Stack>
                    <div
                      style={{
                        margin: 'auto',
                        textAlign: 'center',
                        marginTop: '100px',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                          ButtonDownloadHistoryIsSubmitting ||
                          selected.length == 0 ||
                          errors.startDate ||
                          errors.endDate
                        }
                      >
                        {t('BTNDownload')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}

        {displayPage === 'BillingCSV' && <DownloadBillsCSV />}

        {displayPage === 'XML' && (
          <div>
            <div>
              <br></br>
              <Typography variant="h6" gutterBottom display="inline">
                {t('paraDownload2')}
              </Typography>
              <br></br>
              <br></br>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={handleSubmitWithCpoIds}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form noValidate>
                    <Stack
                      direction="row"
                      spacing={12}
                      alignItems="center"
                      border={'none'}
                      width="100%"
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '70px',
                          textAlign: 'center',
                          marginTop: '30px',
                          margin: 'auto',
                        }}
                      >
                        <div
                          style={{
                            width: '20%',
                            height: '50px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <div style={{ backgroundColor: 'transparent' }}>
                            <div>
                              <Button
                                disabled={stations.length === 0}
                                endIcon={<ArrowDropDownIcon fontSize="small" />}
                                variant="contained"
                                color="primary"
                                aria-haspopup="true"
                                onClick={handleCpoIdClick}
                                id="mutiple-select-cpo"
                                ml={8}
                                ref={cpoButtonComponent}
                                sx={{
                                  minWidth: '95%',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {cpoId}
                              </Button>
                              <Select
                                id="chart-cpo"
                                name="selectedCpo"
                                variant="filled"
                                size="small"
                                open={anchorCpo}
                                labelId="mutiple-select-cpo"
                                input={
                                  <OutlinedInput
                                    sx={{ fontSize: '2rem', padding: '12px' }}
                                    label="Tag"
                                  />
                                }
                                value={selectedCpo}
                                onChange={handleTestCpoIdChange}
                                IconComponent={() => null}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      left: `${cpoLeftPosition}px !important`,
                                      top: '295px !important',
                                      maxHeight: '200px !important',
                                      fontSize: '2 !important',
                                      width: `${cpoDropBoxWidth}px !important`,
                                    },
                                  },
                                }}
                                onClose={handleTestCpoIdChange}
                                style={{ display: 'none' }}
                              >
                                {operators.map((operator, index) => (
                                  <MenuItem key={index} value={operator.cpoId}>
                                    {operator.cpoId + ': ' + operator.cpoName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            width: '20%',
                            height: '50px',
                            textAlign: 'left',
                            float: 'left',
                          }}
                        >
                          <div style={{ backgroundColor: 'transparent' }}>
                            <div>
                              <Button
                                disabled={stations.length === 0}
                                endIcon={<ArrowDropDownIcon fontSize="small" />}
                                variant="contained"
                                color="primary"
                                aria-haspopup="true"
                                onClick={handleLocationWithCpoClick}
                                id="mutiple-select-label-station"
                                ml={8}
                                ref={locationButtonErp}
                              >
                                {locationName}{' '}
                                <Chip label={selectedLocationWithCpo.length} size="small" />
                              </Button>
                              <Select
                                id="chart-location-stationWithCpo"
                                variant="filled"
                                size="small"
                                open={anchorLocationWithCpo}
                                labelId="mutiple-select-label-station"
                                input={
                                  <OutlinedInput
                                    sx={{ fontSize: '2rem' }}
                                    label="Tag"
                                  />
                                }
                                multiple
                                value={selectedLocationWithCpo}
                                onChange={handleTestLocationWithCpoChange}
                                renderValue={(selectedLocationWithCpo) =>
                                  selectedLocationWithCpo.length
                                }
                                IconComponent={() => null}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      left: `${locationErpLeftPosition}px !important`,
                                      top: '295px !important',
                                      maxHeight: '200px !important',
                                      fontSize: '2 !important',
                                      backgroundColor: 'transparent',
                                    },
                                  },
                                  // getcontentanchorel: null,
                                }}
                                onClose={handleTestLocationWithCpoChange}
                                style={{ display: 'none' }}
                              >
                                {stationsWithCpo.length > 0 ? (
                                  <MenuItem
                                    value="all"
                                    divider
                                    style={{
                                      display:
                                        selectedCpo === '' ? 'none' : true,
                                    }}
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={isAllSelectedStations}
                                      indeterminate={
                                        selectedLocationWithCpo.length > 0 &&
                                        selectedLocationWithCpo.length <
                                          stationsWithCpo.length
                                      }
                                    />
                                    <ListItemText
                                      primary={t('LBLALLLocations')}
                                    />
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    value="all"
                                    divider
                                    style={{
                                      display:
                                        selectedCpo === '' ? 'none' : true,
                                    }}
                                  >
                                    <ListItemText
                                      primary={t('nodataforOperator')}
                                    />
                                  </MenuItem>
                                )}
                                {stationsWithCpo?.map((station) => (
                                  <MenuItem
                                    size="small"
                                    key={station.locationId}
                                    value={station.locationId}
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={
                                        selectedLocationWithCpo.indexOf(
                                          station.locationId
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primaryTypographyProps={{
                                        fontSize: '10px',
                                      }}
                                    >
                                      {station.locationId +
                                        ': ' +
                                        station.locationName}
                                    </ListItemText>
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            width: '20%',
                            height: '50px',
                            textAlign: 'right',
                            float: 'left',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: 'transparent',
                              marginLeft: '10px',
                              marginTop: '-15px',
                            }}
                          >
                            <div>
                              <TextField
                                select
                                fullWidth
                                id="paymentMethod"
                                label={t('LBLpaymentMethod')}
                                onChange={handlePaymentMethodChange}
                                value={paymentMethodSelected}
                                name="PaymentMethod"
                              >
                                {paymentMethodsTypes.map((method, index) => (
                                  <MenuItem
                                    size="small"
                                    key={index}
                                    value={method.value}
                                  >
                                    {t(method.translationKey)}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            width: '20%',
                            height: '50px',
                            textAlign: 'center',
                            float: 'left',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: 'transparent',
                              marginLeft: '10px',
                            }}
                          >
                            <div>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="de"
                              >
                                <DatePicker
                                  label={t('fromDatePosting')}
                                  PaperProps={{ sx: { boxShadow: shadows[3] } }}
                                  value={values.startDate}
                                  onChange={(newValue) => {
                                    handleFromDatePostingChange(
                                      newValue,
                                      setFieldValue
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextField}
                                      {...params}
                                      required
                                      name="startDate"
                                      margin="none"
                                      autoComplete="off"
                                      helperText={errors.startDate}
                                      error={errors.startDate}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'TT.MM.JJJJ',
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            width: '20%',
                            height: '50px',
                            textAlign: 'center',
                            float: 'left',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: 'transparent',
                              marginLeft: '10px',
                            }}
                          >
                            <div>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="de"
                              >
                                <DatePicker
                                  PaperProps={{ sx: { boxShadow: shadows[3] } }}
                                  label={t('endPostingDate')}
                                  value={values.endDate}
                                  onChange={(newValue) => {
                                    handleUntilDatePostingChange(
                                      newValue,
                                      setFieldValue
                                    );
                                  }}
                                  maxDate={dayjs()}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextField}
                                      {...params}
                                      name="endDate"
                                      margin="none"
                                      autoComplete="off"
                                      helperText={errors.endDate}
                                      error={errors.endDate}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'TT.MM.JJJJ',
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Stack>
                    <div
                      style={{
                        margin: 'auto',
                        textAlign: 'center',
                        marginTop: '100px',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                          ButtonDownloadErpIsSubmitting ||
                          selectedCpo === '' ||
                          selectedLocationWithCpo.length == 0 ||
                          operators.length == 0 ||
                          errors.startDate ||
                          errors.endDate
                        }
                      >
                        {t('BTNDownload')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}

        <Suspense fallback={<div />}>
          <FailFeedback
            open={error}
            title={'Fehler'}
            severity="warning"
            message={
              <>
                <Typography align="center">
                  {t('LBLSomethingwentWrong')}
                </Typography>
              </>
            }
            handleClose={() => {
              setError(false);
              setError204(false);
            }}
          />
          <FailFeedback
            open={error204}
            title={t('noinfoFound')}
            severity="warning"
            message={
              <>
                <Typography align="center">{t('LBLNoDataDC')}</Typography>
              </>
            }
            handleClose={() => {
              setError(false);
              setError204(false);
            }}
          />
          <FailFeedback
            open={error404}
            title={t('title404')}
            severity="warning"
            message={
              <>
                <Typography align="center">{t('body404')}</Typography>
              </>
            }
            handleClose={() => {
              setError(false);
              setError204(false);
              setError404(false);
            }}
          />
          <FailFeedback
            open={xmlError}
            title={t('LBLMissingInfo')}
            severity="warning"
            message={
              <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
                {xmlErrorMessage}
              </Typography>
            }
            handleClose={closeErrorFeedback}
          />
        </Suspense>
      </Box>
    </React.Fragment>
  );
}

export default DownloadCenter;
