import React from 'react';
import 'dayjs/locale/de';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import { useMemo, useState, useEffect, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import shadows from '@mui/material/styles/shadows';
import InputAdornment from '@mui/material/InputAdornment';
/**
 * @param {PropTypes.InferProps<UserCreationFormERP.propTypes>} props
 */
const UserCreationFormERP = ({
  payload,
  billingTypeValue,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext,
  cpoPaymentPreference,
  editFlag,
}) => {
  const { t } = useTranslation();
  const [minStartDate, setMinStartDate] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [billingType, setBillingType] = useState('');
  const formikRef = useRef();
  useEffect(() => {
    let billingType = null;
    if (payload?.billingType) {
      billingType = payload?.billingType;
      if (editFlag == true && billingType == 'WITH_BILLING') {
        setSelectedPaymentMethod(
          payload?.contractPaymentDetails?.paymentMethod
        );
      }
    } else {
      if (
        cpoPaymentPreference?.includes('BANK_TRANSFER') ||
        cpoPaymentPreference?.includes('SEPA') ||
        cpoPaymentPreference?.includes('STRIPE_SEPA')
      ) {
        billingType = 'WITH_BILLING';
        if (formikRef.current) {
          formikRef.current.setFieldError('paymentMethod', t('reqField'));
          formikRef.current.setFieldValue('paymentMethod', '');
        }
      } else {
        billingType = 'NO_BILLING';
      }
    }
    setBillingType(billingType);
  }, [cpoPaymentPreference]);

  useEffect(() => {
    if (submit > 0) document.getElementById('subERP').click();
    setSubmit(0);
    var today = new Date();
    var yyyy = today.getFullYear();
    setMinStartDate(
      yyyy + today.toLocaleString('en-US', { month: '2-digit' }) + '01'
    );
  }, [submit, minStartDate]);
  const initialValues = {
    paymentMethod: selectedPaymentMethod,
    debitAccountNr: payload?.erpSystemParameters?.debitAccountNr || '',
    costCenterNumber: payload?.erpSystemParameters?.costCenterNumber || '',
    vatId: payload?.erpSystemParameters?.vatId ?? '',
    contractMandateReference:
      payload?.contractPaymentDetails?.contractMandateReference || '',
  };

  let validationSchema = useMemo(
    () =>
      Yup.object().shape(
        billingTypeValue === 'NO_BILLING'
          ? {
              debitAccountNr: Yup.number()
                .typeError(t('NumericOnly'))
                .label(t('custacnum')),
              costCenterNumber: Yup.number()
                .typeError(t('NumericOnly'))
                .label(t('costcnum')),
              vatId: Yup.number()
                .typeError(t('NumericOnly'))
                .label(t('vatId')),
              paymentMethod: Yup.string().label('paymentMethod'),

              contractMandateReference: Yup.string().label(t('mandateref')),
            }
          : selectedPaymentMethod === 'SEPA'
            ? {
                debitAccountNr: Yup.number()
                  .typeError(t('NumericOnly'))
                  .label(t('custacnum')),
                costCenterNumber: Yup.number()
                  .typeError(t('NumericOnly'))
                  .label(t('costcnum')),
                vatId: Yup.number()
                  .typeError(t('NumericOnly'))
                  .label(t('vatId')),
                paymentMethod: Yup.string()
                  .required(t('reqField'))
                  .label('paymentMethod'),
                contractMandateReference: Yup.string()
                  .required(t('mandatereq'))
                  .label(t('mandateref')),
              }
            : {
                debitAccountNr: Yup.number()
                  .typeError(t('NumericOnly'))
                  .label(t('custacnum')),
                costCenterNumber: Yup.number()
                  .typeError(t('NumericOnly'))
                  .label(t('costcnum')),
                vatId: Yup.number()
                  .typeError(t('NumericOnly'))
                  .label(t('vatd')),
                paymentMethod: Yup.string()
                  .required(t('reqField'))
                  .label('paymentMethod'),
                contractMandateReference: Yup.string().label(t('mandateref')),
              }
      ),
    [selectedPaymentMethod]
  );

  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      paymentMethod:
        billingTypeValue === 'NO_BILLING' ? null : selectedPaymentMethod,
      erpSystemParameters: {
        debitAccountNr: values.debitAccountNr,
        costCenterNumber: values.costCenterNumber,
        vatId: values.vatId,
      },
      contractPaymentDetails: {
        paymentMethod: selectedPaymentMethod,
        contractMandateReference: values?.contractMandateReference,
      },
    };
    setPayload(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);
  };

  const handlePaymentMethodSelect = (handleChange) => (event) => {
    const newValue = event.target.value;
    setSelectedPaymentMethod(newValue);
    handleChange(event);
  };
  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ touched, handleChange, isValid }) => (
        <Form noValidate>
          <>
            {setCanNext(touched && isValid)}
            {billingTypeValue === 'WITH_BILLING' ? (
              <Stack
                my={5}
                direction="row"
                spacing={2}
                alignItems="right"
                width="50%"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  width="100%"
                >
                  <Typography
                    fontWeight={1000}
                    variant="subtitle1"
                    id="autorisierung-uber-labelERP"
                  >
                    {t('paymethod')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  width="100%"
                >
                  <Box width="100%">
                    <Field
                      required
                      disabled={editFlag == true}
                      value={selectedPaymentMethod}
                      component={Select}
                      formControl={{
                        required: true,
                        fullWidth: true,
                        size: 'small',
                        margin: 'none',
                      }}
                      onChange={handlePaymentMethodSelect(handleChange)}
                      defaultValue={''}
                      id="paymentType1"
                      name="paymentMethod"
                      label={t('paymethod')}
                      MenuProps={{
                        PaperProps: { sx: { boxShadow: shadows[3] } },
                      }}
                    >
                      {cpoPaymentPreference?.includes('BANK_TRANSFER') ? (
                        <MenuItem value={'BANK_TRANSFER'}>
                          {t('rechnung')}
                        </MenuItem>
                      ) : null}
                      {cpoPaymentPreference?.includes('SEPA') ? (
                        <MenuItem value={'SEPA'}>{t('sepa')}</MenuItem>
                      ) : null}
                      {cpoPaymentPreference?.includes('STRIPE_SEPA') ? (
                        <MenuItem value={'STRIPE_SEPA'}>
                          {t('sepaStripemode')}
                        </MenuItem>
                      ) : null}
                    </Field>
                  </Box>
                </Stack>
              </Stack>
            ) : (
              <div></div>
            )}

            {billingTypeValue === 'WITH_BILLING' ? (
              <div>
                {selectedPaymentMethod === 'SEPA' ? (
                  <Stack my={5} direction="row" alignItems="center" spacing={8}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      width="100%"
                    >
                      <Box position="relative" width="100%">
                        <Typography
                          fontWeight={1000}
                          variant="subtitle1"
                          id="autorisierung-uber-labelERP"
                        >
                          {t('biSepa')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              <div></div>
            )}
            {billingTypeValue === 'WITH_BILLING' ? (
              <div>
                {selectedPaymentMethod === 'SEPA' ? (
                  <Stack
                    mb={5}
                    paddingRight={2}
                    direction="row"
                    width="49%"
                    spacing={12}
                  >
                    <Field
                      fullWidth
                      margin="none"
                      required
                      component={TextField}
                      id="MandatsreferenzId"
                      name="contractMandateReference"
                      label={t('mandateref')}
                      InputProps={{
                        title: t('fillthis'),
                      }}
                    />
                  </Stack>
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              <div></div>
            )}
            {selectedPaymentMethod === 'STRIPE_SEPA' ? (
              <div></div>
            ) : (
              <Stack my={5} direction="row" alignItems="center" spacing={8}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  width="100%"
                >
                  <Box position="relative" width="100%">
                    <Typography
                      fontWeight={1000}
                      variant="subtitle1"
                      id="autorisierung-uber-labelERP"
                    >
                      {t('referp')}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            )}
            {selectedPaymentMethod === 'STRIPE_SEPA' ? (
              <div></div>
            ) : (
              <Stack mb={5} direction="row" spacing={8}>
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="debitAccountNrid"
                  name="debitAccountNr"
                  label={t('custacnum')}
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                />
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="costCenterNumberId"
                  name="costCenterNumber"
                  label={t('costcnum')}
                  InputProps={{
                    title: t('fillthis'),
                  }}
                />
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="vatId"
                  name="vatId"
                  label={t('vatId')}
                  inputProps={{ min: 0, step: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Stack>
            )}
            <Button sx={{ display: 'none' }} id="subERP" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

UserCreationFormERP.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserCreationFormERP;